import React from 'react'
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types'
import EmailIcon from '@mui/icons-material/Email'
import SmartphoneIcon from '@mui/icons-material/Smartphone'

const Contact = () => {
  const content = useEpiContent<Content>()
  return (
    <Card data-testid="contact">
      <CardContent>
        <Typography variant="h6" component="h2" mb={2}>
          {content?.heading}
        </Typography>
        <Typography variant="body1">{content?.ingress}</Typography>
        <List>
          <ListItem divider={false} disablePadding>
            <ListItemButton
              data-testid="contactEmailLink"
              LinkComponent={'a'}
              href={`mailto: ${content?.email}`}
            >
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText
                data-testid="contactEmail"
                sx={{ color: 'primary.main' }}
                primary={content?.email}
              />
            </ListItemButton>
          </ListItem>
          <ListItem divider={false} disablePadding>
            <ListItemButton
              data-testid="contactPhoneLink"
              LinkComponent={'a'}
              href={`tel: ${content?.phone}`}
            >
              <ListItemIcon>
                <SmartphoneIcon />
              </ListItemIcon>
              <ListItemText
                data-testid="contactPhone"
                sx={{ color: 'primary.main' }}
                primary={content?.phone}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

export default Contact
